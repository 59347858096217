@import "../../styles/_chunk";

.bundle-landing-page {
  .bundle-deadlink-message {
    font-weight: 400;
    font-size: 18px;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }

  .bundle-deadlink-email-subscribe-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    .email-subscribe {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 55%;
      }
    }
  }

  .section-heading-timer {
    font-size: 1.125rem;

    .timer-title {
      margin-right: 0.5rem;
    }

    .sd-timer {
      @include brand-property(color, brand-core);

      font-weight: 700;
      text-transform: uppercase;

      .colon {
        margin: 0 0.25rem;
      }
    }
  }

  .homepage-ebook-section {
    margin-bottom: -1rem;
  }
}
