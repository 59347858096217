@import "../../../styles/_chunk";

.bundle-ended-banner-container {
  @include brand-property(border-color, brand-core, important);

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $primary-core;
  padding: 0.625rem;
  margin-bottom: 2rem;

  @include media-breakpoint-only(xs) {
    flex-direction: column;
  }

  .bundle-ended-message {
    display: flex;
    align-items: center;
    font-size: 1rem;

    @include media-breakpoint-only(xs) {
      padding-bottom: 0.5rem;
    }

    svg {
      width: 20px;
      height: 20px;

      @include media-breakpoint-up(xxl) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
