@import "../../styles/_chunk";

.fake-ts-item {
  margin-bottom: 2px;
  align-items: center;
  position: relative;
  display: none;

  @include media-breakpoint-up(lg) {
    margin-bottom: 1rem;
    height: 151px;
    display: block;
  }

  @include media-breakpoint-up(xl) {
    height: 156px;
  }

  @include media-breakpoint-up(xxl) {
    height: 183px;
  }

  .ts-item-wrapper {
    height: 100%;
    display: flex;
    background: $card-background-dark;
    border-radius: $border-radius;
    overflow: hidden;

    @include theme(light) {
      background: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    .ts-number {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .fake-number {
        width: 20px;
        height: 50px;
        background-color: $grey-10;

        @include theme(light) {
          background-color: $grey-3;
        }
      }
    }

    .ts-body {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .fake-product-name {
        height: 22px;
        width: 150px;
        background-color: $grey-10;
        margin-bottom: 0.25rem;

        @include theme(light) {
          background-color: $grey-3;
        }
      }

      .fake-product-icons {
        height: 20px;
        width: 100px;
        background-color: $grey-10;

        @include theme(light) {
          background-color: $grey-3;
        }
      }
    }

    .ts-pricing {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .card-price-container {
        justify-content: flex-end;
        height: 38px;

        .fake-price {
          width: 50px;
          height: 16px;
          background-color: $grey-10;

          @include theme(light) {
            background-color: $grey-3;
          }
        }
      }

      .hit-card-buttons-container {
        .fake-btn {
          width: 76px;
          height: 40px;
          background-color: $grey-10;

          @include theme(light) {
            background-color: $grey-3;
          }
        }
      }
    }
  }
}
