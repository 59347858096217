@import "../../styles/_chunk";

.top-sellers {
  .top-sellers-top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .title-container {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: inherit;
      }
    }
  }

  .sort-dropdown {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: inherit;
    }

    select {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 180px;
      }
    }
  }
}
