@import "../../../styles/_chunk";

.GoldenBundleBanner {
  background-color: $black;
  margin: 1rem 0 2rem 0;
  display: block;
  min-height: 80px;

  @include media-breakpoint-up(sm) {
    height: 138px;
  }

  @include media-breakpoint-up(md) {
    height: 186px;
  }

  @include media-breakpoint-up(lg) {
    height: 149px;
  }

  @include media-breakpoint-up(xl) {
    height: 178px;
  }

  @include media-breakpoint-up(xxl) {
    height: 209px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
